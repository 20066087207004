import React, { useState } from "react";
import { Card, Tab, Nav } from "react-bootstrap";
import PageTitle from "../layouts/PageTitle";
import UsersTable from "./UsersTable";
import { useLocation } from "react-router-dom";
import ButtonLoader from "../components/Common/ButtonLoader/ButtonLoader";
import { sendForceUpdate } from "../../services/UserServices/UserService";
import { notifyError, notifyTopRight } from "../components/Common/Toaster";

export default function ForceUpdate() {
  const [loader, setLoader] = useState(false);
  const [formData, setFormData] = useState({
    iosVersionCode: "",
    iosBuildNumber: "",
    androidVersionName: "",
    androidVersionCode: "",
  });

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);

    try {
      const response = await sendForceUpdate(formData);
      notifyTopRight("Force Update Sent Successfully.");
      setFormData({
        iosVersionCode: "",
        iosBuildNumber: "",
        androidVersionName: "",
        androidVersionCode: "",
      });

      setLoader(false);
    } catch (error) {
      setLoader(false);
      notifyError("Something went wrong please try again!");
    }
  };
  return (
    <div>
      <PageTitle activeMenu="Update" motherMenu="Force Update" />
      <Card>
        <Card.Body>
          <form onSubmit={onSubmit} style={{ marginTop: "50px" }}>
            <h3>Android</h3>
            <div
              className="input-group border bg-white input-group-sm"
              style={{ borderRadius: "8px" }}
            >
              <input
                style={{
                  paddingBottom: "25px",
                  paddingTop: "25px",
                  borderRadius: "10px",
                  fontSize: "14px",
                }}
                type="text"
                name="table_search"
                className="form-control float-right border-0"
                placeholder="Android Version Name"
                value={formData.androidVersionName}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    androidVersionName: e.target.value,
                  });
                }}
              />
            </div>
            <div
              className="input-group border bg-white input-group-sm"
              style={{ borderRadius: "8px", marginTop: "20px" }}
            >
              <input
                style={{
                  paddingBottom: "25px",
                  paddingTop: "25px",
                  borderRadius: "10px",
                  fontSize: "14px",
                }}
                type="text"
                name="table_search"
                className="form-control float-right border-0"
                placeholder="Android Version Code"
                value={formData.androidVersionCode}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    androidVersionCode: e.target.value,
                  });
                }}
              />
            </div>

            <h3 style={{ marginTop: "50px" }}>iOS</h3>
            <div
              className="input-group border bg-white input-group-sm"
              style={{ borderRadius: "8px" }}
            >
              <input
                style={{
                  paddingBottom: "25px",
                  paddingTop: "25px",
                  borderRadius: "10px",
                  fontSize: "14px",
                }}
                type="text"
                name="table_search"
                className="form-control float-right border-0"
                placeholder="Ios Build Number"
                value={formData.iosBuildNumber}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    iosBuildNumber: e.target.value,
                  });
                }}
              />
            </div>
            <div
              className="input-group border bg-white input-group-sm"
              style={{ borderRadius: "8px", marginTop: "20px" }}
            >
              <input
                style={{
                  paddingBottom: "25px",
                  paddingTop: "25px",
                  borderRadius: "10px",
                  fontSize: "14px",
                }}
                type="text"
                name="table_search"
                className="form-control float-right border-0"
                placeholder="Ios Version Code"
                value={formData.iosVersionCode}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    iosVersionCode: e.target.value,
                  });
                }}
              />
            </div>

            <div className="text-center" style={{ marginTop: "50px" }}>
              <button
                type="submit"
                className="btn btn-primary btn-block"
                disabled={loader}
              >
                {loader ? (
                  <>
                    <ButtonLoader /> Send
                  </>
                ) : (
                  "Send"
                )}
              </button>
            </div>
          </form>
        </Card.Body>
      </Card>
    </div>
  );
}
