export const MenuList = [
  //Dashboard
  {
    title: "Dashboard",
    classsChange: "mm-collapse",
    iconStyle: <i className="flaticon-381-networking"></i>,
    to: "/dashboard",
  },
  {
    title: "User Management",
    classsChange: "mm-collapse",
    iconStyle: <i className="flaticon-381-user-9"></i>,
    to: "/user-management",
  },
  {
    title: "Select Users",
    classsChange: "mm-collapse",
    iconStyle: <i className="flaticon-381-user-9"></i>,
    to: "/user-management-select",
  },
  {
    title: "Members",
    classsChange: "mm-collapse",
    iconStyle: <i className="flaticon-381-user-9"></i>,
    to: "/members",
  },
  {
    title: "Face Verification",
    classsChange: "mm-collapse",
    iconStyle: <i class="flaticon-381-user"></i>,
    to: "/face-verification",
  },
  {
    title: "Post Management",
    classsChange: "mm-collapse",
    iconStyle: <i className="flaticon-381-save"></i>,
    to: "/post-management",
  },
  {
    title: "Support Requests",
    classsChange: "mm-collapse",
    iconStyle: <i className="flaticon-381-list-1"></i>,
    to: "/support-requests",
  },
  {
    title: "Report Management",
    classsChange: "mm-collapse",
    iconStyle: <i className="flaticon-381-warning-1"></i>,
    to: "/report-management",
  },
  {
    title: "Referral Codes",
    classsChange: "mm-collapse",
    iconStyle: <i className="flaticon-381-list"></i>,
    to: "/referral-codes",
  },
  {
    title: "FAQs",
    classsChange: "mm-collapse",
    iconStyle: <i className="flaticon-381-menu"></i>,
    to: "/faqs-category-list",
  },
  {
    title: "Withdrawal Requests",
    classsChange: "mm-collapse",
    iconStyle: <i className="flaticon-381-list-1"></i>,
    to: "/withdrawal-requests",
  },

  {
    title: "Push Notifications",
    classsChange: "mm-collapse",
    iconStyle: <i className="flaticon-381-notification"></i>,
    to: "/push-notifications",
  },
  {
    title: "Send Messages",
    classsChange: "mm-collapse",
    iconStyle: <i className="flaticon-381-networking"></i>,
    to: "/send-messages",
  },
  {
    title: "Force Update",
    classsChange: "mm-collapse",
    iconStyle: <i className="flaticon-381-networking"></i>,
    to: "/force-update",
  },
  // {
  //     title: 'Dashboard',
  //     classsChange: 'mm-collapse',
  //     iconStyle: <i className="flaticon-381-networking"></i>,
  //     content: [
  //         {
  //             title: 'Dashboard',
  //             to: '/dashboard',
  //         },
  //         // {
  //         //     title: 'Analytics',
  //         //     to: '/analytics',
  //         // },
  //         // {
  //         //     title: 'Reviews',
  //         //     to: '/reviews',
  //         // },
  //         // {
  //         //     title: 'Order',
  //         //     to: '/order',
  //         // },
  //         // {
  //         //     title: 'Order List',
  //         //     to: '/order-list',

  //         // },
  //         // {
  //         //     title: 'General Customers',
  //         //     to: '/general-customers',
  //         // },
  // 		// {
  //         //     title: 'Task',
  //         //     to: '/task',
  //         // },
  //     ],
  // },
];
