import React from "react";
import { FixedSizeList as List } from "react-window";
import { Table, Badge, Dropdown } from "react-bootstrap";

const UserTableSingle = ({
  users,
  selectedIds,
  handleCheckboxChange,
  imgBaseURl,
  dummmyImg,
  isAllSelected,
  handleSelectAll,
}) => {
  const Row = ({ index, style }) => {
    const item = users[index];
    if (!item) return null; // Prevent rendering issues

    return (
      <div style={{ ...style, display: "table", width: "100%" }}>
        <tr key={item?._id} className="pointer">
          <td>
            <input
              type="checkbox"
              className="custom-control-input"
              checked={selectedIds.includes(item?._id)}
              onChange={(e) => {
                e.stopPropagation();
                handleCheckboxChange(item?._id);
              }}
              style={{ width: "18px", height: "18px", cursor: "pointer" }}
            />
          </td>
          <td>
            <img
              src={
                item?.images?.[0]?.url
                  ? imgBaseURl + item.images[0].url
                  : dummmyImg
              }
              alt="img"
              width={100}
              height={80}
              className="rounded"
            />
          </td>
          <td>{item?.name}</td>
          <td className="pointer">
            <img
              src={
                item?.verificationImage?.url
                  ? imgBaseURl + item.verificationImage.url
                  : dummmyImg
              }
              alt="img"
              width={100}
              height={80}
              className="rounded"
            />
          </td>
          <td>{item?.email}</td>
          <td>
            {item?.gender &&
              item?.gender.charAt(0).toUpperCase() + item.gender.slice(1)}
          </td>
          <td>{item?.phoneNumber}</td>
        </tr>
      </div>
    );
  };

  return (
    <div style={{ width: "100%", height: "600px", overflow: "auto" }}>
      <table
        className="table"
        style={{ width: "100%", borderCollapse: "collapse" }}
      >
        {/* Table Head (Fixed) */}
        <thead>
          <tr>
            <th>
              <input
                type="checkbox"
                className="custom-control-input"
                checked={isAllSelected}
                onChange={handleSelectAll}
                style={{ width: "18px", height: "18px", cursor: "pointer" }}
              />
            </th>
            <th>Image</th>
            <th>Name</th>
            <th>Verification</th>
            <th>Email</th>
            <th>Gender</th>
            <th>Phone</th>
          </tr>
        </thead>
      </table>

      {/* Virtualized Table Body */}
      <div style={{ height: "500px", overflow: "auto" }}>
      <Table responsive>
          <tbody>
            <List
              height={500}
              itemCount={users.length}
              itemSize={110}
              width="100%"
            >
              {Row}
            </List>
          </tbody>
          </Table>
      </div>
    </div>
  );
};

export default UserTableSingle;
