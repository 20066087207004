import React, { useState } from "react";
import { Card, Tab, Nav } from "react-bootstrap";
import PageTitle from "../layouts/PageTitle";
import UsersTable from "./UsersTable";
import { useLocation } from "react-router-dom";
import MultiUsersTable from "./MultiUsersTable";

export default function MultiUserList() {
  const location = useLocation();

  return (
    <div>
      <PageTitle activeMenu="Users List" motherMenu="User Management" />
      <Card>
        <Card.Body>
          <div className="default-tab">
            <MultiUsersTable />
          </div>
        </Card.Body>
      </Card>
    </div>
  );
}
