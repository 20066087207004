import axiosInstance from "../AxiosInstance";
import {
  FQA_CATEGORY_LIST,
  FQA_CATEGORY_ORDER,
  FQA_LIST,
  FQA_LIST_ORDER,
  FQA_SUBCATEGORY_LIST,
  GRAPH_DATA,
  POSTS,
  RECOVER_ACCOUNT_API,
  REDEEM_LIST,
  REFFERAl_LIST,
  REMOVE_IMAGES,
  REPORTS_LIST,
  REUPLOAD_PROFILE_IMAGE,
  SEND_FORCE_UPDATE,
  SEND_MESSAGES,
  SEND_NOTIFICATION_FEMALE,
  SUPPORTLIST,
  TOGGLE_AUTO_APPROVE,
  UPDATE_PREMIUM,
  USERLIST,
  USERLISTPREMIUM,
  USERLIST_EXPORT,
  USER_DETAILS,
  USER_REFERRAL_AMOUNT,
  USER_REFERRAL_LIST,
  VERIFICATION_LIST,
  VERIFY_MULTIPLE,
  WITHDRAWAL_REQUESTS,
} from "./UserEndpoint";

// export function getUserList(
//   page,
//   limit,
//   search,
//   gender,
//   status,
//   isFormFilled,
//   signUpType,
//   startDate,
//   endDate
// ) {
//   return axiosInstance.get(
//     USERLIST +
//       `?page=${page}&limit=${limit}&search=${search}&gender=${gender}&userList=${status}&formFill=${isFormFilled}&loginType=${signUpType}&startDate=${startDate}&endDate=${endDate}`
//   );
// }
export function getUserList(
  page,
  limit,
  search,
  gender,
  status,
  isFormFilled,
  signUpType,
  activeStatus,
  startDate,
  endDate
) {
  const params = new URLSearchParams({
    page: page,
    limit: limit,
    search: search,
    gender: gender,
    userList: status,
    formFill: isFormFilled,
    loginType: signUpType,
    sortBy: activeStatus,
    isExport: false,
  });

  if (startDate) {
    params.append("startDate", startDate);
  }
  if (endDate) {
    params.append("endDate", endDate);
  }

  return axiosInstance.get(`${USERLIST}?${params.toString()}`);
}

export function getUserListExport(
  search,
  gender,
  status,
  isFormFilled,
  signUpType,
  activeStatus,
  startDate,
  endDate,
  isExport,
  selectedIds
) {
  if (isExport) {
    const params = {
      search: search,
      gender: gender,
      userList: status,
      formFill: isFormFilled,
      loginType: signUpType,
      sortBy: activeStatus,
      userIds: selectedIds,
    };

    if (startDate) {
      params.append("startDate", startDate);
    }
    if (endDate) {
      params.append("endDate", endDate);
    }

    return axiosInstance.post(USERLIST_EXPORT,params);
  } else {
    const params = {
      search: search,
      gender: gender,
      userList: status,
      formFill: isFormFilled,
      loginType: signUpType,
      sortBy: activeStatus,
    };

    if (startDate) {
      params.append("startDate", startDate);
    }
    if (endDate) {
      params.append("endDate", endDate);
    }

    return axiosInstance.post(USERLIST_EXPORT,params);
  }
}

export function getUserListSenderMessage(
  page,
  limit,
  search,
  gender,
  status,
  isFormFilled,
  signUpType,
  activeStatus,
  startDate,
  endDate
) {
  const params = new URLSearchParams({
    page: page,
    limit: limit,
    search: search,
    gender: JSON.stringify(gender),
    userList: status,
    formFill: isFormFilled,
    loginType: JSON.stringify(signUpType),
    sortBy: activeStatus,
    isExport: false,
  });

  if (startDate) {
    params.append("startDate", startDate);
  }
  if (endDate) {
    params.append("endDate", endDate);
  }

  return axiosInstance.get(`${USERLIST}?${params.toString()}`);
}

export function getUserExportList() {
  return axiosInstance.get(`${USERLIST}?isExport=true`);
}

export function getPremiumUserList(
  page,
  limit,
  type,
  status,
  search,
  filterByAdmin,
  // gender,
  // status,
  // isFormFilled,
  // signUpType,
  startDate,
  endDate,
  isPast
) {
  const params = new URLSearchParams({
    page: page,
    limit: limit,
    type: type,
    search: search,
    filter: filterByAdmin,
    // gender:gender,
    userList: status,
    // formFill:isFormFilled,
    // loginType:signUpType,
    isPast: isPast,
  });

  if (startDate) {
    params.append("startDate", startDate);
  }
  if (endDate) {
    params.append("endDate", endDate);
  }

  return axiosInstance.get(`${USERLISTPREMIUM}?${params.toString()}`);
}

export function getUserDetails(id) {
  return axiosInstance.get(USER_DETAILS + `?userId=${id}`);
}
export function updateImages(postData) {
  return axiosInstance.put(REMOVE_IMAGES, postData);
}

export function updatePremium(postData) {
  return axiosInstance.put(UPDATE_PREMIUM, postData);
}

export function updateNonPremium(id) {
  const postData = { userId: id };
  return axiosInstance.put(UPDATE_PREMIUM, postData);
}

export function sendNotificationFemale(id, type) {
  const postData = { userId: id, sendTo: type };
  return axiosInstance.post(SEND_NOTIFICATION_FEMALE, postData);
}

export function recoverAccountApi(id) {
  const postData = { userId: id};
  return axiosInstance.put(RECOVER_ACCOUNT_API, postData);
}

export function getGraphData(type) {
  return axiosInstance.get(GRAPH_DATA + `?type=${type}`);
}
export function deleteUser(id) {
  return axiosInstance.delete(USERLIST + `?userId=${id}`);
}
export function blockUser(id) {
  const postData = { userId: id };
  return axiosInstance.put(USERLIST, postData);
}

export function getVerificationRequests(
  page,
  limit,
  type,
  status,
  gender,
  order
) {
  return axiosInstance.get(
    VERIFICATION_LIST +
      `?page=${page}&limit=${limit}&type=${type}&filter=${status}&gender=${gender}&filterByOrder=${order}`
  );
}

export function getProfileImageVerificationRequests(
  page,
  limit,
  isRejected,
  // type,
  // status,
  gender,
  order
) {
  return axiosInstance.get(
    REUPLOAD_PROFILE_IMAGE +
      `?page=${page}&limit=${limit}&gender=${gender}&filterByOrder=${order}&isRejected=${isRejected}`
  );
}

export function approveMultiRequest(formData) {
  return axiosInstance.put(VERIFY_MULTIPLE, formData);
}
export function toggleAutoApprove(formData) {
  return axiosInstance.put(TOGGLE_AUTO_APPROVE, formData);
}
export function approveRejectRequest(formData) {
  return axiosInstance.put(VERIFICATION_LIST, formData);
}
////////////////=====================report list==============/////////////////////////////////////

export function getReportList(page, limit) {
  return axiosInstance.get(REPORTS_LIST + `?page=${page}&limit=${limit}`);
}
/////////////===================support======================//////////////////////////////////////

export function getSupportList(page, limit, search, sort) {
  return axiosInstance.get(
    SUPPORTLIST + `?page=${page}&limit=${limit}&search=${search}&sort=${sort}`
  );
}

export function UpdateSupportStatus(formdata) {
  return axiosInstance.put(SUPPORTLIST, formdata);
}

export function deleteSupport(id) {
  return axiosInstance.put(SUPPORTLIST + `?ticketId=${id}`);
}

/////////////////////////================Refferal List========================////////////////////////

export function getRefferalList(
  page,
  limit,
  search,
  searchBy,
  sortBy,
  selectedTab
) {
  return axiosInstance.get(
    REFFERAl_LIST +
      `?page=${page}&limit=${limit}&search=${search}&searchBy=${searchBy}&sortOrder=${sortBy}&createdBy=${selectedTab}`
  );
}

export function createReferralCode(formData) {
  return axiosInstance.post(REFFERAl_LIST, formData);
}

export function deleteCode(id) {
  return axiosInstance.delete(REFFERAl_LIST + `?referralId=${id}`);
}

export function getRedeemedList(page, limit, type, id) {
  return axiosInstance.get(
    REDEEM_LIST + `?page=${page}&limit=${limit}&type=${type}&referralId=${id}`
  );
}

/////////////////////////================ Faqs   ===========================////////////////////////
export function getFaqsList(page, limit, id) {
  return axiosInstance.get(FQA_LIST + `?categoryId=${id}`);
}

export function createFaq(formData) {
  return axiosInstance.post(FQA_LIST, formData);
}

export function editFaq(formData) {
  return axiosInstance.put(FQA_LIST, formData);
}

export function deleteFaq(id, faqId) {
  return axiosInstance.delete(FQA_LIST + `?questionId=${id}&faqId=${faqId}`);
}
export function changeFaqOrder(formData) {
  return axiosInstance.put(FQA_LIST_ORDER, formData);
}

export function getFaqsCategory(page, limit) {
  return axiosInstance.get(FQA_CATEGORY_LIST + `?page=${page}&limit=${limit}`);
}

export function changeFaqCategoryOrder(formData) {
  return axiosInstance.put(FQA_CATEGORY_ORDER, formData);
}
export function createCategory(formData) {
  return axiosInstance.post(FQA_CATEGORY_LIST, formData);
}
export function sendMessageApi(formData) {
  return axiosInstance.post(SEND_MESSAGES, formData);
}
export function sendForceUpdate(formData) {
  return axiosInstance.put(SEND_FORCE_UPDATE, formData);
}
export function editCategory(formData) {
  return axiosInstance.put(FQA_CATEGORY_LIST, formData);
}
export function deleteFaqCategory(id) {
  return axiosInstance.delete(FQA_CATEGORY_LIST + `?categoryId=${id}`);
}

//////////////================subcategory============////////////////

export function getFaqsSubCategory(page, limit, categoryId) {
  return axiosInstance.get(
    FQA_SUBCATEGORY_LIST +
      `?page=${page}&limit=${limit}&categoryId=${categoryId}`
  );
}

export function createSubCategory(formData) {
  return axiosInstance.post(FQA_SUBCATEGORY_LIST, formData);
}

export function deleteFaqSubCategory(subCategoryId) {
  return axiosInstance.delete(
    FQA_SUBCATEGORY_LIST + `?subcategoryId=${subCategoryId}`
  );
}

//////////////////////======================withdrawal requests===========////////////////////////

export function getWithdrawalRequests(page, limit) {
  return axiosInstance.get(
    WITHDRAWAL_REQUESTS + `?page=${page}&limit=${limit}`
  );
}

export function withdrawalRequestAction(formData) {
  return axiosInstance.put(WITHDRAWAL_REQUESTS, formData);
}

export function getUserReferralList(page, limit, filterStatus, id) {
  return axiosInstance.get(
    USER_REFERRAL_LIST +
      `?page=${page}&limit=${limit}&type=${filterStatus}&userId=${id}`
  );
}
export function getPayNowList(id) {
  return axiosInstance.get(USER_REFERRAL_AMOUNT + `?referralId=${id}`);
}
export function payNow(formData) {
  return axiosInstance.put(USER_REFERRAL_AMOUNT, formData);
}

/////////////=============== posts===================//////////////////////

export function getPostList(page, limit) {
  return axiosInstance.get(POSTS + `?page=${page}&limit=${limit}`);
}
export function deletePost(id) {
  return axiosInstance.delete(POSTS + `?postId=${id}`);
}
