import React, { useState } from "react";
import ReactPaginate from "react-paginate";

export default function Pagination({ pageCount, pageValue, setPage, setUpdateNotification }) {
  const [inputPage, setInputPage] = useState(pageValue + 1); // Start from 1

  function newFunction(data) {
    console.log(data, "value in function");
    setPage(data);
    if (typeof setUpdateNotification === 'function') {
      setUpdateNotification(true);
    }
  }

  const handleInputChange = (event) => {
    setInputPage(event.target.value);
  };

  const handleInputSubmit = (event) => {
    event.preventDefault();
    const page = Number(inputPage) - 1; // Convert to zero-based index
    if (page >= 0 && page < pageCount) {
      newFunction(page);
    } else {
      // Optionally, reset input or show an error message
      setInputPage(pageValue + 1);
    }
  };

  return (
    <>
      {pageCount > 1 && (
        <div className="pagination-container">
          <ReactPaginate
            pageCount={pageCount}
            forcePage={pageValue}
            previousLabel={"<"}
            nextLabel={">"}
            breakLabel={"....."}
            marginPagesDisplayed={2}
            containerClassName={"pagination justify-content-center"}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
            previousClassName={"page-item"}
            previousLinkClassName={"page-link"}
            nextClassName={"page-item"}
            nextLinkClassName={"page-link"}
            breakClassName={"page-item"}
            breakLinkClassName={"page-link"}
            activeClassName={"page-item active"}
            onPageChange={(selected) => {
              newFunction(selected.selected);
            }}
          />
          <form onSubmit={handleInputSubmit} className="d-flex justify-content-end mt-3">
            <input
              type="number"
              value={inputPage}
              onChange={handleInputChange}
              min={1}
              max={pageCount}
              className="form-control"
              style={{ width: "100px", marginRight: "10px" }}
            />
            <button type="submit" className="btn btn-primary">Go</button>
          </form>
        </div>
      )}
    </>
  );
}
