import React, { useState, useEffect } from "react";
import { Table, Badge, Dropdown } from "react-bootstrap";
import {
  getUserList,
  blockUser,
  deleteUser,
  getUserExportList,
  sendNotificationFemale,
  getUserListExport,
} from "../../services/UserServices/UserService";
import Spinner from "../components/Common/Spinner";
import { notifyError, notifyTopRight } from "../components/Common/Toaster";
import Pagination from "../components/Common/Pagination";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import dummmyImg from "../../images/profile/images.jpeg";
import { RangeDatePicker } from "@y0c/react-datepicker";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  activeStatusFilterPost,
  formFilledFilterPost,
  genderFilterPost,
  resetFilterPost,
  serachFilterPost,
  statusFilterPost,
  typeFilterPost,
} from "../../store/actions/FilterActions";
import * as XLSX from "xlsx";
import UserTableSingle from "./UserTableSingle";

const MultiUsersTable = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const imgBaseURl = process.env.REACT_APP_IMAGE_BASEURL;
  const [loader, setLoader] = useState(false);
  const [users, setUsers] = useState([]);
  const [listLength, setListLength] = useState(0);
  const [imageForView, setImageForView] = useState("");
  const [view, setView] = useState(false);
  const [currentPage, setCurrentPage] = useState(
    location?.state?.page ? location?.state?.page : 0
  );
  const [loginData, setLoginData] = useState(null);
  const searchFilter = useSelector((state) => state.filter.filter.search);
  const genderFilter = useSelector((state) => state.filter.filter.gender);
  const statusFilter = useSelector((state) => state.filter.filter.status);
  const formFilledFilter = useSelector(
    (state) => state.filter.filter.formFilled
  );
  const typeFilter = useSelector((state) => state.filter.filter.type);
  const activeStatusFilter = useSelector(
    (state) => state.filter.filter.activeStatus
  );

  const [search, setSearch] = useState(searchFilter);
  const [initialFetch, setInitialFetch] = useState(true);
  const [gender, setGender] = useState(genderFilter);
  const [status, setStatus] = useState(statusFilter);
  const [isFormFilled, setIsFormFilled] = useState(formFilledFilter);
  const [signUpType, setSignUpType] = useState(typeFilter);
  const [activeStatus, setActiveStatus] = useState(activeStatusFilter);
  const [newUsers, setNewUsers] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [exportLoader, setExportLoader] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);

  const handleCheckboxChange = (id) => {
    setSelectedIds((prev) =>
      prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id]
    );
  };

  function onDateChange(...args) {
    console.log(args, "args");
    setStartDate(moment(args[0]).format("YYYY-MM-DD"));
    {
      args[1]
        ? setEndDate(moment(args[1]).format("YYYY-MM-DD"))
        : setEndDate(null);
    }
  }
  const svg1 = (
    <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect x="0" y="0" width="24" height="24"></rect>
        <circle fill="#000000" cx="5" cy="12" r="2"></circle>
        <circle fill="#000000" cx="12" cy="12" r="2"></circle>
        <circle fill="#000000" cx="19" cy="12" r="2"></circle>
      </g>
    </svg>
  );
  const headers = [
    { label: "CONTACT ID", key: "_id" },
    { label: "EMAIL", key: "email" },
    { label: "FIRST NAME", key: "name" },
    { label: "LAST NAME", key: "" },
    { label: "SMS", key: "phoneNumber" },
  ];
  const getTableData = async () => {
    setLoader(true);
    try {
      const response = await getUserListExport(
        search,
        gender,
        status,
        isFormFilled,
        signUpType,
        activeStatus,
        startDate,
        endDate,
        false
      );
      setUsers(response.data.data.users);
      setLoader(false);
      setInitialFetch(false);
    } catch (error) {
      console.log(error, "error");
      setLoader(false);
    }
  };
  function onReset() {
    // setDateRangePickerKey((prev) => prev + 1);
    setStartDate(null);
    setEndDate(null);
  }

  useEffect(() => {
    getTableData();
  }, [gender, status, isFormFilled, signUpType, activeStatus, endDate]);

  let timeoutId;
  // Define a helper function for debouncing
  const debounce = (func, delay) => {
    return (...args) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func.apply(null, args);
      }, delay);
    };
  };

  const debouncedHandleFetch = debounce(getTableData, 500);
  useEffect(() => {
    if (initialFetch === false) {
      setCurrentPage(0);
      debouncedHandleFetch();
      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, []);

  const fetchDataForExport = async () => {
    setExportLoader(true);
    try {
      
      const filteredData = users.filter(item => selectedIds.includes(item._id));
    const formattedData = filteredData.map((user) => ({
      Id: user._id,
      Email: user.email,
      Name: user.name,
      "Phone Number": `${user.countryCode || ""}${user.phoneNumber || ""}`,
      Gender: user.gender
        ? user.gender.charAt(0).toUpperCase() + user.gender.slice(1)
        : "",
    }));

    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(formattedData);

    XLSX.utils.book_append_sheet(workbook, worksheet, "Users");

    XLSX.writeFile(workbook, "Users-List.xlsx");
      setExportLoader(false);

    } catch (error) {
      console.log(error, "error");
      setExportLoader(false);
      notifyError("Failed to fetch export data");
    }
  };

  const isAllSelected = users.length > 0 && selectedIds.length === users.length;

  const handleSelectAll = () => {
    if (isAllSelected) {
      setSelectedIds([]);
    } else {
      setSelectedIds(users.map((user) => user._id));
    }
  };

  return loader ? (
    <Spinner />
  ) : (
    <>
      <div className="d-block justify-content-between align-items-center">
        <div className="d-flex justify-content-between align-items-center">
          <div className="col-4" style={{ display: "flex", columnGap: "10px" }}>
            <div
              className="input-group border bg-white input-group-sm"
              style={{ borderRadius: "8px" }}
            >
              <input
                style={{
                  paddingBottom: "25px",
                  paddingTop: "25px",
                  borderRadius: "10px",
                  fontSize: "14px",
                }}
                type="text"
                name="table_search"
                className="form-control float-right border-0"
                placeholder="Search"
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                  dispatch(serachFilterPost(e.target.value));
                  setSelectedIds([]);
                }}
              />
              {/* <div className="input-group-append">
                <button
                  type="button"
                  className="btn btn-default"
                  onClick={getTableData}
                >
                  <i className="fa fa-search" />
                </button>
              </div> */}
            </div>
            <button className="btn btn-primary" onClick={getTableData}>
              <i className="fa fa-search" />
            </button>
          </div>
          <RangeDatePicker
            // key={dateRangePickerKey}
            className="form-control"
            startText="Start"
            endText="End"
            startPlaceholder="Start Date"
            endPlaceholder="End Date"
            onChange={onDateChange}
          />
          {endDate && (
            <button
              type="button"
              className="btn btn-primary py-2"
              onClick={() => onReset()}
            >
              Reset Date
            </button>
          )}

          <button
            type="button"
            className="btn btn-primary py-2"
            onClick={() => {
              dispatch(resetFilterPost());
              setGender("all");
              setIsFormFilled("all");
              setStatus("all");
              setSignUpType("all");
              setActiveStatus("createdAt");
              setSelectedIds([]);
            }}
          >
            Reset Filter
          </button>
          <div className="">
            {exportLoader ? (
              <button className="btn btn-primary" disabled>
                <span
                  className="spinner-border spinner-border-sm me-2"
                  role="status"
                  aria-hidden="true"
                ></span>
                Exporting...
              </button>
            ) : (
              <button
                className="btn btn-primary"
                onClick={() => fetchDataForExport()}
              >
                Export <i className="flaticon-381-send"></i>
              </button>
            )}
          </div>
        </div>

        <div className="d-flex justify-content-between align-items-center gap-3 mt-2">
          {" "}
          <div className="mb-2 mb-md-0 w-100">
            <label className="text-black font-w600 fs-14">
              Filter by Gender
            </label>

            <select
              className="form-control"
              value={gender}
              onChange={(e) => {
                setGender(e.target.value);
                dispatch(genderFilterPost(e.target.value));
                setSelectedIds([]);
              }}
            >
              <option value={"all"}>All</option>
              <option value={"male"}>Male</option>
              <option value={"female"}>Female</option>
              <option value={"premium"}>Member</option>
              <option value={"nonPremium"}>Non Member</option>
              <option value={"pastMember"}>Past Member</option>
            </select>
          </div>
          <div className="mb-2 mb-md-0 w-100">
            <label className="text-black font-w600 fs-14">
              Sign Up Complete
            </label>

            <select
              className="form-control"
              value={isFormFilled}
              onChange={(e) => {
                setIsFormFilled(e.target.value);
                dispatch(formFilledFilterPost(e.target.value));
                setSelectedIds([]);
              }}
            >
              <option value={"all"}>All</option>
              <option value={true}>Completed</option>
              <option value={false}>Not Completed</option>
            </select>
          </div>
          <div className="mb-2 mb-md-0 w-100">
            <label className="text-black font-w600 fs-14">
              Filter by Status
            </label>

            <select
              className="form-control"
              value={status}
              onChange={(e) => {
                setStatus(e.target.value);
                dispatch(statusFilterPost(e.target.value));
                setSelectedIds([]);
              }}
            >
              <option value={"all"}>All</option>
              <option value={"enable"}>Enabled</option>
              <option value={"disable"}>Disabled</option>
              <option value={"deactivate"}>Deactivated</option>
              <option value={"delete"}>Deleted</option>
              <option value={"adminDeleted"}>Deleted By Admin</option>
            </select>
          </div>
          <div className="mb-2 mb-md-0 w-100">
            <label className="text-black font-w600 fs-14">
              Filter by Sign Up
            </label>
            <select
              className="form-control"
              value={signUpType}
              onChange={(e) => {
                setSignUpType(e.target.value);
                dispatch(typeFilterPost(e.target.value));
                setSelectedIds([]);
              }}
            >
              <option value={"all"}>All</option>
              <option value={"email"}>Email</option>
              <option value={"phoneNumber"}>Phone Number</option>
              <option value={"social"}>Social Login</option>
            </select>
          </div>
          <div className="mb-2 mb-md-0 w-100">
            <label className="text-black font-w600 fs-14">
              Filter by Active Status
            </label>
            <select
              className="form-control"
              value={activeStatus}
              onChange={(e) => {
                setActiveStatus(e.target.value);
                dispatch(activeStatusFilterPost(e.target.value));
                setSelectedIds([]);
              }}
            >
              <option value={"createdAt"}>Created At</option>
              <option value={"lastActive"}>Last Active</option>
            </select>
          </div>
        </div>
      </div>
         <UserTableSingle
            users={users}
            selectedIds={selectedIds}
            handleCheckboxChange={handleCheckboxChange}
            imgBaseURl={imgBaseURl}
            dummmyImg={dummmyImg}
            isAllSelected={isAllSelected}
            handleSelectAll={handleSelectAll}
            />
      {users?.length === 0 && !loader && (
        <div className="justify-content-center d-flex my-5">
          Sorry, Data Not Found!
        </div>
      )}
    </>
  );
};

export default MultiUsersTable;
